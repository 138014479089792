<template>
  <div class="mt-2">
    <div v-if="listType === 'players'">
      <v-data-table
        v-if="peopleList.length"
        :headers="headers" :items="peopleList" dense class="table--has-pointer"
      >
        <template #[`item.avatar`]="{ item }">
          <v-avatar size="36">
            <img v-if="item.avatar" alt="Avatar" :src="item.avatar">
            <v-icon v-else color="primary" v-text="'person'" />
          </v-avatar>
        </template>
        <template #[`item.birthdate`]="{ item }">
          <span>{{ formatBirthdate(item.birthdate) }}</span>
        </template>
        <template #[`item.licenseCode`]="{ item }">
          <copy-text :text="item.licenseCode" />
        </template>
        <template #[`item.parentEmail`]="{ item }">
          <copy-text :text="item.parentEmail" />
        </template>
        <template #[`item.email`]="{ item }">
          <copy-text :text="item.email" />
        </template>
        <template #[`item.userId`]="{ item }">
          <div class="d-flex justify-space-between align-center">
            <span v-if="item.userId">
              <span class="mr-2">{{ $t('common.yes') }}</span>
              <v-icon
                v-if="isUserManager"
                color="primary" @click="$router.push({ name: 'project-player', params: { playerId: item.id } })"
                v-text="'logout'"
              />
            </span>
            <span v-else v-text="$t('common.no')" />
            <v-menu v-if="canEditPlayers || ((item.email || item.parentEmail) && hasEnrollRequiresPlayerCodeFeatures)">
              <template #activator="{ on, attrs }">
                <v-btn color="primary" icon v-bind="attrs" v-on="on" @click.prevent @mousedown.stop>
                  <v-icon v-text="'more_vert'" />
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-if="canEditPlayers" @click="showPlayersFormDialog = true; playerToUpdate = item">
                  <v-list-item-title>{{ $t('common.modify') }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="canEditPlayers"
                  @click="$confirm($t('project.confirmDeletePlayer')).then(confirm => confirm && deletePlayer(item))"
                >
                  <v-list-item-title>{{ $t('common.delete') }}</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="(item.email || item.parentEmail) && hasEnrollRequiresPlayerCodeFeatures" @click="sendEmailPlayer(item.id)">
                  <v-list-item-title v-text="$t('user.resendEmail')" />
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-data-table>
    </div>
    <div v-else-if="listType === 'staff'">
      <v-data-table
        v-if="peopleList.length"
        :headers="headers" :items="peopleList" dense class="table--has-pointer"
      >
        <template #[`item.avatar`]="{ item }">
          <v-avatar size="36">
            <img v-if="item.avatar" alt="Avatar" :src="item.avatar">
            <v-icon v-else color="primary" v-text="'person'" />
          </v-avatar>
        </template>
        <template #[`item.email`]="{ item }">
          <copy-text :text="item.email" />
        </template>
        <template #[`item.phone`]="{ item }">
          <div class="d-flex justify-space-between align-center">
            <span>{{ `+${item.phone.code} ${item.phone.number}` }}</span>
            <v-menu>
              <template #activator="{ on, attrs }">
                <v-btn color="primary" icon v-bind="attrs" v-on="on" @click.prevent @mousedown.stop>
                  <v-icon v-text="'more_vert'" />
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="showStaffFormDialog = true; staffToUpdate = item">
                  <v-list-item-title>{{ $t('common.modify') }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="$confirm($t('project.confirmDeleteStaff')).then(confirm => confirm && deleteStaff(item))">
                  <v-list-item-title>{{ $t('common.delete') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-data-table>
    </div>
    <v-row
      no-gutters
      style="flex: initial !important;"
      class="py-6"
    >
      <v-btn
        v-if="listType === 'staff'"
        outlined depressed color="primary" class="mr-4 mb-4" @click="showStaffFormDialog = true, staffToUpdate = null"
      >
        <v-icon small color="primary" v-text="'add'" />
        <h5 class="mx-4" v-text="$t('common.add')" />
      </v-btn>
      <v-btn
        v-if="listType === 'players' && canEditPlayers"
        outlined
        depressed
        color="primary"
        class="mr-4 mb-4"
        @click="showPlayersFormDialog = true, playerToUpdate = null"
      >
        <v-icon small color="primary" v-text="'add'" />
        <h5 class="mx-4" v-text="$t('common.add')" />
      </v-btn>
      <v-btn
        v-if="listType === 'players'"
        outlined
        depressed
        color="primary"
        @click="showImportPlayersDialog = true"
      >
        <v-icon small color="primary" v-text="'file_upload'" />
        <h5 class="mx-4" v-text="$t('common.importCsv')" />
      </v-btn>
    </v-row>
    <v-row
      v-if="listType === 'players'"
    >
      <player-form-dialog
        v-if="showPlayersFormDialog"
        :player="playerToUpdate" :emails-players="emailsPlayers" :parent-emails-players="parentEmailsPlayers" :numbers-players="numbersPlayers"
        @create="createPlayer"
        @update="updatePlayer"
        @close-dialog="showPlayersFormDialog = false"
      />
      <import-players-from-csv
        v-if="showImportPlayersDialog"
        :organization-id="organizationId"
        :project-id="projectId"
        :cohort-id="cohortId"
        :team-id="teamId"
        :player-list="peopleList"
        @close-dialog="showImportPlayersDialog = false"
      />
    </v-row>
    <v-row
      v-if="listType === 'staff'"
    >
      <staff-form-dialog
        v-if="showStaffFormDialog"
        :staff="staffToUpdate"
        @create="createStaff"
        @update="updateStaff"
        @close-dialog="showStaffFormDialog = false"
      />
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import dateFilter from '@/utils/mixins/dateFilter'

export default {
  name: 'PeopleList',
  components: {
    PlayerFormDialog: () => import('@/modules/club/PlayerFormDialog'),
    StaffFormDialog: () => import('@/modules/club/StaffFormDialog'),
    ImportPlayersFromCsv: () => import('@/modules/club/ImportPlayersFromCsv'),
  },
  mixins: [dateFilter],
  props: {
    listType: {
      type: String,
      required: true,
    },
    organizationId: {
      type: String,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
    cohortId: {
      type: String,
      required: true,
    },
    teamId: {
      type: String,
      required: true,
    },
    peopleList: {
      type: Array,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showPlayersFormDialog: false,
      showImportPlayersDialog: false,
      playerToUpdate: null,
      showStaffFormDialog: false,
      staffToUpdate: null,
    }
  },
  computed: {
    ...mapGetters('project', ['isUserManager', 'hasEnrollRequiresPlayerCodeFeatures', 'hasDisablePlayerEditByClubFeature', 'isUserSubmanager']),
    emailsPlayers: ({ listType, peopleList }) => listType === 'players' ? peopleList.map(p => p.email || null) : [],
    parentEmailsPlayers: ({ listType, peopleList }) => listType === 'players' ? peopleList.map(p => p.parentEmail || null) : [],
    numbersPlayers: ({ listType, peopleList }) => listType === 'players' ? peopleList.map(p => p.number || null) : [],
    canEditPlayers: ({ hasDisablePlayerEditByClubFeature, isUserSubmanager }) => !hasDisablePlayerEditByClubFeature || isUserSubmanager,
  },
  methods: {
    createPlayer(data) {
      const { organizationId, projectId, cohortId, teamId } = this
      this.runAsync(() => {
        this.$store.dispatch('club/createPlayer', { organizationId, projectId, cohortId, teamId, data })
        this.$store.dispatch('club/updateByClubmanager', { organizationId, projectId, updatedType: 'teamPlayers' })
      })
    },
    updatePlayer(data) {
      const { organizationId, projectId, cohortId, teamId } = this
      this.runAsync(() => { this.$store.dispatch('club/updatePlayer', { organizationId, projectId, cohortId, teamId, data }) })
    },
    deletePlayer(player) {
      const { organizationId, projectId, cohortId, teamId } = this
      this.runAsync(() => {
        this.$store.dispatch('club/deletePlayer', { organizationId, projectId, cohortId, teamId, playerId: player.id })
      })
    },
    async sendEmailPlayer(playerId) {
      const { organizationId, projectId, cohortId } = this
      await this.runAsync(() => this.$store.dispatch('club/sendEmailPlayerAddInTeam', { playerId, organizationId, projectId, cohortId }))
    },
    createStaff(data) {
      const { organizationId, projectId, cohortId, teamId } = this
      this.runAsync(() => {
        this.$store.dispatch('club/createStaff', { organizationId, projectId, cohortId, teamId, data })
        this.$store.dispatch('club/updateByClubmanager', { organizationId, projectId, updatedType: 'teamStaff' })
      })
    },
    updateStaff(data) {
      const { organizationId, projectId, cohortId, teamId } = this
      this.runAsync(() => { this.$store.dispatch('club/updateStaff', { organizationId, projectId, cohortId, teamId, data }) })
    },
    deleteStaff(staff) {
      const { organizationId, projectId, cohortId, teamId } = this
      this.runAsync(() => { this.$store.dispatch('club/deleteStaff', { organizationId, projectId, cohortId, teamId, staffId: staff.id }) })
    },
  },
}
</script>

<style lang="scss" scoped>

::v-deep .v-data-table tbody tr {
  height: 5rem;
}
</style>
